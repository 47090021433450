import React, { useState, useEffect } from 'react';
import { Modal, Box, Button } from '@mui/material'; // MUI для стилизации модалки

interface Permission {
  m_type: string;
  m_name: string;
  permission: string;
}

interface Notification {
  UID: string;
  Name: string;
  Permissions: Permission[];
}

interface NotificationModalProps {
  open: boolean;
  handleClose: () => void;
}

const NotificationModal: React.FC<NotificationModalProps> = ({ open, handleClose }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const pass = localStorage.getItem('userInfo');

  useEffect(() => {
    if (open) {
      fetch('https://1c.rixap.ru/hd_test/Hs/notice/get_user_p',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: pass || '',
          },
        }
      )
        .then(response => response.json())
        .then((data: Notification[]) => setNotifications(data))
        .catch(error => console.error('Ошибка:', error));
    }
  }, [open]);

  const handleCheckboxChange = (notificationUID: string, permissionType: string, newValue: boolean) => {
    const updatedNotifications = notifications.map(notification => {
      if (notification.UID === notificationUID) {
        return {
          ...notification,
          Permissions: notification.Permissions.map(permission =>
            permission.m_type === permissionType
              ? { ...permission, permission: newValue ? 'true' : 'false' }
              : permission
          )
        };
      }
      return notification;
    });

    setNotifications(updatedNotifications);

    // Отправляем обновленные данные на сервер
    fetch('https://1c.rixap.ru/hd_test/Hs/notice/set_user_p', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: pass || '',
      },
      body: JSON.stringify(updatedNotifications),
    })
      .then(response => response.json())
      .then(data => console.log('Успех:', data))
      .catch(error => console.error('Ошибка:', error));
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          padding: 4,
          backgroundColor: 'white',
          maxWidth: { xs: '95%', sm: 600, md: 800 },
          margin: 'auto',
          marginTop: { xs: '20%', sm: '10%' },
          color: '#000',
          fontFamily: 'Roboto',
          fontWeight: 400,
          lineHeight: '15.733px',
          borderRadius: '20px',
          overflowY: 'auto',
          maxHeight: '90vh', // Чтобы модалка не выходила за пределы экрана
        }}
      >
        <h2 style={{ fontSize: '18px' }}>Уведомления</h2>
        {/* Адаптивная таблица для больших экранов */}
        <Box sx={{ display: { xs: 'none', md: 'block' }, overflowX: 'auto' }}>
          <table
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              marginTop: '20px',
            }}
          >
            <thead>
              <tr>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd', minWidth: '120px' }}>Уведомление</th>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd', minWidth: '80px' }}>Пуш</th>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd', minWidth: '80px' }}>СМС</th>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd', minWidth: '120px' }}>Система</th>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd', minWidth: '120px' }}>Почта</th>
              </tr>
            </thead>
            <tbody>
              {notifications.map(notification => (
                <tr key={notification.UID}>
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{notification.Name}</td>
                  {notification.Permissions.map(permission => (
                    <td
                      key={permission.m_type}
                      style={{ textAlign: 'center', padding: '8px', borderBottom: '1px solid #ddd' }}
                    >
                      <input
                        type="checkbox"
                        checked={permission.permission === 'true'}
                        onChange={e =>
                          handleCheckboxChange(notification.UID, permission.m_type, e.target.checked)
                        }
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>

        {/* Адаптивная версия для мобильных устройств */}
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          {notifications.map(notification => (
            <Box
              key={notification.UID}
              sx={{
                borderBottom: '1px solid #ddd',
                padding: '16px',
                marginBottom: '8px',
                borderRadius: '10px',
                backgroundColor: '#f9f9f9',
              }}
            >
              <Box sx={{ marginBottom: '10px' }}>
                <strong>{notification.Name}</strong>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                <span>Пуш</span>
                <input
                  type="checkbox"
                  checked={notification.Permissions.find(p => p.m_type === 'push')?.permission === 'true'}
                  onChange={e =>
                    handleCheckboxChange(notification.UID, 'push', e.target.checked)
                  }
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                <span>СМС</span>
                <input
                  type="checkbox"
                  checked={notification.Permissions.find(p => p.m_type === 'sms')?.permission === 'true'}
                  onChange={e =>
                    handleCheckboxChange(notification.UID, 'sms', e.target.checked)
                  }
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                <span>Система</span>
                <input
                  type="checkbox"
                  checked={notification.Permissions.find(p => p.m_type === 'system')?.permission === 'true'}
                  onChange={e =>
                    handleCheckboxChange(notification.UID, 'system', e.target.checked)
                  }
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Почта</span>
                <input
                  type="checkbox"
                  checked={notification.Permissions.find(p => p.m_type === 'email')?.permission === 'true'}
                  onChange={e =>
                    handleCheckboxChange(notification.UID, 'email', e.target.checked)
                  }
                />
              </Box>
            </Box>
          ))}
        </Box>
        
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          sx={{
            marginTop: '20px',
            borderRadius: '20px',
            width: '100%', // На мобильных кнопка растягивается на всю ширину
          }}
        >
          Закрыть
        </Button>
      </Box>
    </Modal>
  );
};

export default NotificationModal;
