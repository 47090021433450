// import React, { useEffect, useState } from 'react';

// import styles from './NotificationBar.module.scss';
// import { AppType } from '@shared/types';

// import { NotificationCard } from '@components/UI/Card';
// import Preloader from '@components/UI/Preloader/Preloader';
// import { SimpleBigButton } from '@components/UI/Button';
// import { fetchDataPostSimple } from 'api';

// const NotificationBar = () => {
//   const [currTasks, setCurrTasks] = useState<AppType[]>([]);
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     fetchDataPostSimple('newmessages')
//       .then((res) => setCurrTasks(res))
//       .catch((err) => console.error(err))
//       .finally(() => setIsLoading(false));
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const handleReadAllNotifications = () => {
//     setIsLoading(true);
//     fetchDataPostSimple('SelectAll')
//       .then(() => setCurrTasks([]))
//       .catch((err) => console.error(err))
//       .finally(() => setIsLoading(false));
//   };

//   return (
//     <div className={styles.info}>
//       {currTasks.length !== 0 && (
//         <div className={styles.heading}>
//           <SimpleBigButton
//             text={'Отметить все как прочитанные?'}
//             disabled={false}
//             handleClick={handleReadAllNotifications}
//           />
//         </div>
//       )}

//       <div className={styles.cards}>
//         {currTasks.length === 0 ? (
//           <div className={styles.heading}>
//             {isLoading ? <Preloader /> : <p className={styles.text}>Уведомлений нет</p>}
//           </div>
//         ) : (
//           currTasks.map((item) => (
//             <NotificationCard
//               key={item.UID}
//               cardInfo={item}
//               UID={item.UID}
//               notificationText={'Новый комментарий'}
//               onClickHandler={() => {}}
//             />
//           ))
//         )}
//       </div>

//       {/* {filterState.isOpen && <ModalAppFilter />} */}
//     </div>
//   );
// };

// export default NotificationBar;

import React, { useState, useEffect } from 'react';
import NotificationModal from '@components/Modal/NotificationModal/NotificationModal';
import { AppType, AppTypeNotification } from '@shared/types';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';
import { fetchDataPostSimple } from 'api';
import { NotificationCard } from '@components/UI/Card';
import Preloader from '@components/UI/Preloader/Preloader';
import { SimpleBigButton } from '@components/UI/Button';
import { Modal, Box, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import styles from './NotificationBar.module.scss';

const styleDiv = {
  height: 'calc(100vh - 93px)',
};


const NotificationBar = () => {
  const [currTasks, setCurrTasks] = useState<AppTypeNotification[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const fetchMoreTasks = () => {
    // Загрузка данных при прокрутке списка
    // Реализуйте логику для загрузки следующей страницы данных
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDataPostSimple('newmessages')
      .then((res) => {
        setCurrTasks(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  const handleReadAllNotifications = () => {
    setIsLoading(true);
    const commentIds = currTasks.map(task => task.CommentId);
    fetchDataPostSimple('SelectAll', commentIds)
      .then(() => setCurrTasks([]))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  const handleReadOneNotifications = (commentId: string) => {
    setIsLoading(true);
    // const commentIds = currTasks.map(task => task.CommentId);
    fetchDataPostSimple('SelectAll', [commentId])
      // .then(() => setCurrTasks([]))
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  function calculateHeight(itemCount: any) {
    const itemHeight = 167; // Высота каждого элемента списка
    return itemCount * itemHeight; // Общая высота списка
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.info}>
      <div className={styles.heading}>
        {currTasks.length !== 0 && (
          <div className={styles.heading}>
            <SimpleBigButton
              text={'Отметить все как прочитанные?'}
              disabled={false}
              handleClick={handleReadAllNotifications}
            />
          </div>
        )}
        <IconButton 
          onClick={handleOpenModal} 
          style={{ position: 'absolute', right: 10, top: 10 }}
        >
          <SettingsIcon />
        </IconButton>
      </div>
      {currTasks.length === 0 && !isLoading && (
        <p className={styles.text} style={{ textAlign: 'center' }}>Уведомлений нет</p>
      )}
      <div style={styleDiv}>
        <AutoSizer>
          {({ height, width }: { height: number, width: number }) => (
            // <InfiniteLoader
            //   isItemLoaded={(index) => index < currTasks.length}
            //   itemCount={isEnd ? currTasks.length : currTasks.length + 1}
            //   loadMoreItems={fetchMoreTasks}
            // >
            //   {({ onItemsRendered, ref }) => (
                // <div style={{ height: '100%', width: '100%' }}>
                  <List
                    className={styles.cards}
                    height={height}
                    width={width}
                    // itemCount={isEnd ? currTasks.length : currTasks.length + 1}
                    itemCount={currTasks.length}
                    itemSize={105}
                    // onItemsRendered={onItemsRendered}
                    // ref={ref}
                    style={{ overflowY: 'scroll' }}
                  // style={{ padding: '15px auto' }}
                  >
                    {({ index, style }) => {
                      if (index === currTasks.length) {
                        if (isLoading) {
                          return <div style={style}><Preloader /></div>;
                        } else {
                          return null;
                        }
                      }
                      const item = currTasks[index];
                      return (
                        <div style={{ ...style, display: 'flex', justifyContent: 'center', width: '100%' }} onClick={() => handleReadOneNotifications(item.CommentId)}>
                          <NotificationCard
                            key={item.UID}
                            cardInfo={item}
                            UID={item.UID}
                            onClickHandler={() => { }}
                          />
                        </div>
                      );
                    }}
                  </List>
                // </div>
            //   )}
            // </InfiniteLoader>
          )}
        </AutoSizer>
      </div>
      <NotificationModal open={isModalOpen} handleClose={handleCloseModal}/>
    </div>
  );
};

export default NotificationBar;

