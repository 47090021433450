import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { AppType } from '@shared/types';
import { ServiceName } from '@shared/constants';
import { handleBackgroundStatus, handlePriorityColor } from '@shared/hooks';
import defaultAvatar from '@assets/default_avatar.svg';
import organizationAvatar from '@assets/default_organization.svg';
import taskTypeTelegamm from '@assets/UI/Card/task_type_telegramm.svg';
import taskTypeMail from '@assets/UI/Card/task_type_mail.svg';
import cardTimeLogo from '@assets/logo_time.svg';
import cardCommLogo from '@assets/logo_message.svg';
import cardFilesLogo from '@assets/UI/Card/logo_files.svg';

interface ITaskProps {
  cardInfo: AppType;
  notificationText?: string;
  onClickHandler: (v: string) => void;
  UID: string;
  isActive?: boolean;
  isBroken?: boolean;
}

function formatDate(inputDate: string) {
  const date = new Date(inputDate);

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2);

  return `${hours}:${minutes} ${day}.${month}.${year}`;
  // return `${day}.${month}.${year}`;
}

const NewCard: React.FC<ITaskProps> = React.memo(({ UID, cardInfo }) => {
  const navigate = useNavigate();
  const borderColor = handleBackgroundStatus(cardInfo.Status);
  const priorityColor = handlePriorityColor(cardInfo.Priority);

  const handleClick = (UID: string) => {
    navigate(`/applications/${UID}`);
  };

  return (
    <Box onClick={(event) => handleClick(UID)}
      sx={{
        maxWidth: '380px',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
        boxShadow: '3px 3px 16px 0px rgba(0, 0, 0, 0.08)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.01)',
          boxShadow: '3px 3px 16px 0px rgba(0, 0, 0, 0.3)',
        },
        cursor: 'pointer',
        '@media screen and (max-width: 1000px)': {
          width: '380px'
        },
        '@media screen and (max-width: 440px)': { // Медиа-запрос
          width: '90vw',
        }
      }}
    >
      <Card
        sx={{
          boxShadow: 'none',
          transition: 'all .5s ease',
          '&:hover': {
            backgroundColor: '#e8eafd',
          },
        }}>
        <CardContent sx={{
          position: 'relative',
          padding: '15px 10px', margin: '0', border: 'none',
          '&:last-child': {
            paddingBottom: '15px'
          }
        }}>
          {cardInfo.Executor?.UID && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '20px',
                height: '20px',
                borderTop: `10px solid ${borderColor}`,
                borderLeft: `10px solid ${borderColor}`,
                borderBottom: '10px solid transparent',
                borderRight: '10px solid transparent',
                overflow: 'hidden',
              }}
            />
          )}

          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', margin: '-2px' }}>
                <img src={organizationAvatar} alt={cardInfo.Organization?.Name || cardInfo.Service.Name || ServiceName} />
                <Typography variant="body2" sx={{ maxWidth: '200px', fontSize: '13px', fontWeight: '500', letterSpacing: '0.13px', opacity: '0.65' }}>
                  {cardInfo.Organization?.Name || cardInfo.Service.Name || ServiceName}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                {/* <img src={cardTimeLogo} alt='' /> */}
                <Typography variant="body2" sx={{ maxWidth: '190px', fontSize: '13px', fontWeight: '500', letterSpacing: '0.13px', opacity: '0.65' }}>
                  {cardInfo.Date && formatDate(cardInfo.Date)}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '13px', fontWeight: '500', letterSpacing: '0.13px', opacity: '0.65' }}>
                {cardInfo.Executor?.Name || cardInfo.Executor?.FullName}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <img src={defaultAvatar} width='16px' alt={cardInfo.Initiator.Name || cardInfo.Initiator.FullName} />
              <Typography variant="body2" sx={{ maxWidth: '190px', fontSize: '13px', fontWeight: '500', letterSpacing: '0.13px', opacity: '0.65' }}>
                {cardInfo.Initiator.Name || cardInfo.Initiator.FullName}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ background: `${borderColor}`, padding: '8px', borderRadius: '20px', color: '#f8f6ff', fontSize: '13px', fontWeight: '500', letterSpacing: '0.13px', textWrap: 'nowrap' }}>
                {cardInfo.Status}
              </Typography>
            </Box>
          </Box>

          <Typography sx={{ fonsSize: '16px', fontWeight: '500', letterSpacing: '0.16px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{cardInfo.Subject}</Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '13px', fontWeight: '500', letterSpacing: '0.13px', opacity: '0.65' }}>Заявка #{+cardInfo.Number}</Typography>
            <Typography sx={{ color: `${priorityColor}`, fontSize: '13px', fontWeight: '500', letterSpacing: '0.13px', opacity: '0.65' }}>{cardInfo.Priority}</Typography>
            <Box sx={{ display: 'flex', gap:'5px'}}>
              <img src={cardInfo.TypeConnect ? taskTypeTelegamm : taskTypeMail} width='16px' alt="метод получения" />
              {cardInfo.Comments?.length && (
                <Box sx={{display:'flex', gap:'3px'}}>
                  <img src={cardCommLogo} alt="" />
                  <Typography sx={{ fontSize: '13px', fontWeight: '500', letterSpacing: '0.13px', opacity: '0.65' }}>{cardInfo.Comments}</Typography>
                </Box>
              )}
              {cardInfo.Files?.length && (
                <Box sx={{display:'flex', gap:'3px'}}>
                  <img src={cardFilesLogo} alt="" />
                  <Typography sx={{ fontSize: '13px', fontWeight: '500', letterSpacing: '0.13px', opacity: '0.65' }}>{cardInfo.Files}</Typography>
                </Box>
              )}
            </Box>
          </Box>

        </CardContent>
      </Card>
    </Box>
  )
});

export default NewCard;